import { Trans } from '@lingui/macro'
import { RowFixed } from 'components/Row'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const StyledReleases = styled.div`
  align-items: center;
  bottom: 0;
  color: ${({ theme }) => theme.textTertiary};
  display: none;
  padding: 1rem;
  position: fixed;
  left: 0;
  transition: 250ms ease color;
  z-index: 999;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: flex;
  }
`

export default function Releases() {
  return (
    <RowFixed>
      <StyledReleases>
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>beta v3.0.0</Trans>
        </ThemedText.TextSecondary>
      </StyledReleases>
    </RowFixed>
  )
}
